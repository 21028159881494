import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import jsonData from './palabras.json';
import { removeAccentFromVowel } from './utils';

const getRandomQuestion = (checkPresent, checkPreterite, checkImperfect, checkSubjunctive, checkFuture) => {
  const words = Object.keys(jsonData);
  const wordIndex = Math.floor(Math.random() * words.length);
  const word = words[wordIndex];

  const talkers = ['yo', 'tu', 'el', 'nosotros', 'ellos'];
  const talkersStringMap = {
    yo: 'yo',
    tu: 'tú',
    el: 'él/ella/usted',
    nosotros: 'nosotros',
    ellos: 'ellos/ellas/ustedes',
  };
  const talkerIndex = Math.floor(Math.random() * talkers.length);
  const talker = talkers[talkerIndex];

  const tenses = [];
  if (checkPresent) {
    tenses.push('present');
  }
  if (checkPreterite) {
    tenses.push('preterite');
  }
  if (checkImperfect) {
    tenses.push('imperfect');
  }
  if (checkSubjunctive) {
    tenses.push('subjunctive');
  }
  if (checkFuture) {
    tenses.push('future');
  }
  const tenseIndex = Math.floor(Math.random() * tenses.length);
  const tense = tenses[tenseIndex];

  const answer = jsonData[word][tense][talker];

  return {
    word,
    talker: talkersStringMap[talker],
    tense,
    answer,
    example: {
      word: 'hablar',
      answer: jsonData['hablar'][tense][talker],
    },
  };
};

const DailySpanish = () => {
  const [scoreCard, setScoreCard] = useState([]);
  const [count, setCount] = useState(0);
  const [streak, setStreak] = useState(0);
  const [wordInput, setWordInput] = useState('');
  const [question, setQuestion] = useState(null);
  const [checkPresent, setCheckPresent] = useState(true);
  const [checkPreterite, setCheckPreterite] = useState(true);
  const [checkImperfect, setCheckImperfect] = useState(true);
  const [checkSubjunctive, setCheckSubjunctive] = useState(true);
  const [checkFuture, setCheckFuture] = useState(true);

  useEffect(() => {
    document.title = 'Spanish Verb Conjugation Quiz Exercise';
  }, []);
  useEffect(() => {
    setQuestion(getRandomQuestion(checkPresent, checkPreterite, checkImperfect, checkSubjunctive, checkFuture));
  }, [count, checkPresent, checkPreterite, checkImperfect, checkSubjunctive, checkFuture]);
  const handleSubmit = (event) => {
    event.preventDefault();
    ReactGA.event({
      category: 'spanish',
      action: 'submit',
      label: 'quiz submit',
      value: count,
    });
    setScoreCard([
      ...scoreCard,
      {
        isCorrect: compareWithoutAccents(wordInput, answer),
        talker: question.talker,
        tense: question.tense,
        correctAnswer: question.answer,
        yourAnswer: wordInput,
        infinitive: question.word,
      },
    ]);
    setCount(count + 1);
    setWordInput('');
  };
  const compareWithoutAccents = (str1, str2) => {
    if (removeAccentFromVowel(str1) === removeAccentFromVowel(str2)) {
      setStreak(streak + 1);
      return true;
    }
    setStreak(0);
    return false;
  };

  const handleWordChange = (event) => {
    setWordInput(event.target.value.toLowerCase());
  };

  const handleCheckPresentChange = (event) => {
    setCheckPresent(event.target.checked);
  };
  const handleCheckPreteriteChange = (event) => {
    setCheckPreterite(event.target.checked);
  };
  const handleCheckImperfectChange = (event) => {
    setCheckImperfect(event.target.checked);
  };
  const handleCheckSubjunctiveChange = (event) => {
    setCheckSubjunctive(event.target.checked);
  };
  const handleCheckFutureChange = (event) => {
    setCheckFuture(event.target.checked);
  };

  const resetScoreCard = (event) => {
    event.preventDefault();
    setScoreCard([]);
  };

  if (!question) {
    return <div>Loading...</div>;
  }

  const { word, talker, tense, answer, example } = question;

  return (
    <div>
      <h1>Spanish Verb Conjugation Quiz</h1>
      <h2>Choose tenses</h2>
      <div className="checkbox-container">
        <label className="label-container">
          <div className={`custom-checkbox ${checkPresent ? 'checked' : ''}`} />
          Present
          <input type="checkbox" checked={checkPresent} onChange={handleCheckPresentChange} />
        </label>
        <label className="label-container">
          <div className={`custom-checkbox ${checkPreterite ? 'checked' : ''}`} />
          Preterite
          <input type="checkbox" checked={checkPreterite} onChange={handleCheckPreteriteChange} />
        </label>
        <label className="label-container">
          <div className={`custom-checkbox ${checkImperfect ? 'checked' : ''}`} />
          Imperfect
          <input type="checkbox" checked={checkImperfect} onChange={handleCheckImperfectChange} />
        </label>
        <label className="label-container">
          <div className={`custom-checkbox ${checkSubjunctive ? 'checked' : ''}`} />
          Subjunctive
          <input type="checkbox" checked={checkSubjunctive} onChange={handleCheckSubjunctiveChange} />
        </label>
        <label className="label-container">
          <div className={`custom-checkbox ${checkFuture ? 'checked' : ''}`} />
          Future
          <input type="checkbox" checked={checkFuture} onChange={handleCheckFutureChange} />
        </label>
      </div>

      <h4>Streak: {streak}</h4>
      <h3>
        {word + ' '}
        <a href={`https://www.spanishdict.com/translate/${word}`} target="_blank" rel="noreferrer">
          {`[?]`}
        </a>
      </h3>
      <h4>
        {tense} | {talker}
      </h4>

      <form onSubmit={handleSubmit}>
        <input
          type="text"
          id="word-input"
          name="word-input"
          value={wordInput}
          onChange={handleWordChange}
          placeholder="Type the word here"
          autoComplete="off"
        />
        <button type="submit">Enter</button>
      </form>
      <p>
        Ejemplo ({example.word}): {tense} | {talker} → <b>{example.answer}</b>
      </p>
      <div className="scorecard">
        <h4>
          Palabras aprendidas{' '}
          <button className="reset-button" onClick={resetScoreCard}>
            Reset
          </button>
        </h4>

        {scoreCard
          .slice()
          .reverse()
          .map((item, index) => {
            return (
              <p key={`${item.infinitive}_${index}`}>
                <b>
                  <a href={`https://www.spanishdict.com/conjugate/${item.infinitive}`} target="_blank" rel="noreferrer">
                    {item.infinitive}
                  </a>
                </b>{' '}
                | {item.tense} | ({item.talker}) <b>{item.correctAnswer}</b>
                {item.isCorrect ? ' ✅' : ` | your answer: ${item.yourAnswer} ❌`}
              </p>
            );
          })}
      </div>
    </div>
  );
};

export default DailySpanish;
